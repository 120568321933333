import type { AxiosError } from 'axios';

/**
 * A custom error shape used across the app.
 * This is the "source of truth" for how we represent errors.
 */
export interface AppError {
  isNetworkError: boolean;
  status: number | null;
  message: string;
  /**
   * The server's internal or "app-specific" numeric code, if any.
   */
  appErrorCode: number | null;
  /**
   * We store the raw response data here if needed
   * for debugging or for extracting other fields.
   */
  data?: unknown;
}

/**
 * Checks if the given object is an AxiosError (runtime guard).
 */
function isAxiosError(error: unknown): error is AxiosError {
  return (
    typeof error === 'object' &&
    error !== null &&
    // at runtime, it will typically have "isAxiosError: true"
    'isAxiosError' in error
  );
}

/**
 * A relaxed type guard checking if the response data object
 * has at least a "message" or "detail" property (of any type).
 */
function hasMessageOrDetailField(
  data: unknown
): data is { message?: string; detail?: unknown } {
  return (
    typeof data === 'object' &&
    data !== null &&
    (('message' in data &&
      typeof (data as Record<string, unknown>).message === 'string') ||
      'detail' in data)
  );
}

/**
 * Transforms any "unknown" error into a standardized AppError shape
 * (if possible). This helps keep our error-handling consistent.
 */
export function buildAppError(error: unknown): AppError {
  // Default or fallback shape
  const base: AppError = {
    isNetworkError: false,
    status: null,
    appErrorCode: null,
    message:
      'При загрузке данных произошла ошибка. Попробуйте обновить страницу.',
  };

  if (!isAxiosError(error)) {
    // Not even an AxiosError - we can return a fallback
    return {
      ...base,
      message: 'Что-то пошло не так. Код ошибки 986.',
    };
  }

  // It's an AxiosError. Let's see if there's a response
  if (!error.response) {
    // => network error (no response from server)
    return {
      ...base,
      isNetworkError: true,
      message: 'Нет соединения с сервером. Пропал интернет?',
    };
  }

  // => we have a server response with status code
  const status = error.response.status;
  const responseData = error.response.data;

  let serverMessage: string | null = null;
  let appErrorCode: number | null = null;

  // If the data has message/detail fields, parse them to find a user-facing message.
  if (hasMessageOrDetailField(responseData)) {
    serverMessage = extractServerMessage(responseData);
    appErrorCode = extractAppErrorCode(responseData);
  }

  /**
   * 502 is typically returned by CloudFlare when our server is inaccessible,
   * which typically occurs if a request comes while the server is restarting
   * during an update.
   */
  if (status === 502 && !serverMessage) {
    serverMessage =
      'Не удалось обработать. Пожалуйста, повторите операцию ещё раз.';
  }

  // If we couldn't find a helpful message, use a generic fallback.
  return {
    ...base,
    isNetworkError: false,
    status,
    message:
      serverMessage || `Что-то сервер нас не понял, вернул код ${status}.`,
    appErrorCode,
    data: responseData, // keep the raw response data if we want extra debugging info
  };
}

/**
 * Attempts to extract the "best" user-facing error message from
 * the response data:
 *   1) responseData.message as string
 *   2) responseData.detail as string
 *   3) responseData.detail.message as string (if detail is object)
 * Returns null if none are found.
 */
function extractServerMessage(responseData: unknown): string | null {
  // First, ensure `responseData` is an object
  if (typeof responseData !== 'object' || responseData === null) {
    return null;
  }
  // We'll treat it as an indexed object so we can check fields dynamically
  const obj = responseData as Record<string, unknown>;

  // 1) obj.message is a string
  if (typeof obj.message === 'string') {
    return obj.message;
  }
  // 2) obj.detail is a string
  if (typeof obj.detail === 'string') {
    return obj.detail;
  }
  // 3) obj.detail is an object with a `message` string
  if (typeof obj.detail === 'object' && obj.detail !== null) {
    const detailObj = obj.detail as Record<string, unknown>;
    if (typeof detailObj.message === 'string') {
      return detailObj.message;
    }
  }

  return null;
}

/**
 * Extracts the numeric app error code if `detail` is an object
 * containing an `app_error_code` field.
 */
function extractAppErrorCode(responseData: unknown): number | null {
  if (typeof responseData !== 'object' || responseData === null) {
    return null;
  }
  const obj = responseData as Record<string, unknown>;

  if (typeof obj.detail === 'object' && obj.detail !== null) {
    const detailObj = obj.detail as Record<string, unknown>;
    if (typeof detailObj.app_error_code === 'number') {
      return detailObj.app_error_code;
    }
  }
  return null;
}
