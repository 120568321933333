import { defineStore } from 'pinia';
import { authApi } from 'src/boot/axios';
import { Question, QuestionDetails } from 'components/models';
import { useMarketingKitStore } from 'stores/marketing-kit';
import { AppError } from 'src/includes/app-error';
import { useLoadingStore } from './loading';

type ThisStoreState = {
  mk_questions: Question[];
  mk_questions_loaded: boolean;
  fetch_mk_questions_promise: Promise<void> | null;
  question_for_editing: QuestionDetails;
  post_questions: Question[];
  fetch_post_questions_promise: Promise<void> | null;
  post_id: number;
  error: AppError | null;
};

export const useQuestionsStore = defineStore('questions', {
  state: () =>
    ({
      mk_questions: [],
      mk_questions_loaded: false,
      fetch_mk_questions_promise: null,
      question_for_editing: {
        id: 0,
        question: '',
        description: '',
        response_options: '',
        video_id: '',
        reference_code: '',
        notes: '',
      },
      post_questions: [],
      fetch_post_questions_promise: null,
      post_id: 0,
      error: null,
    } as ThisStoreState),

  getters: {
    get_all_post_questions(state) {
      return (post_id: number) => {
        if (state.post_id != post_id || state.post_questions.length == 0) {
          return [];
        }
        return state.post_questions;
      };
    },
    get_mandatory_post_questions(state) {
      return (post_id: number) => {
        if (state.post_id != post_id || state.post_questions.length == 0) {
          return [];
        }
        const mandatory_post_questions = state.post_questions.filter(function (
          question
        ) {
          return question.is_mandatory == true;
        });

        return mandatory_post_questions;
      };
    },
    get_sequential_post_questions(state) {
      return (post_id: number) => {
        if (state.post_id != post_id || state.post_questions.length == 0) {
          return [];
        }
        const sequential_post_questions = state.post_questions.filter(function (
          question
        ) {
          return question.is_sequential == true;
        });

        return sequential_post_questions;
      };
    },
    get_optional_post_questions(state) {
      return (post_id: number) => {
        if (state.post_id != post_id || state.post_questions.length == 0) {
          return [];
        }
        const optional_post_questions = state.post_questions.filter(function (
          question
        ) {
          return (
            !question.is_mandatory &&
            !question.is_part_of_mk &&
            !question.is_sequential
          );
        });

        return optional_post_questions;
      };
    },
    get_question(state) {
      return (question_id: string | number | undefined) => {
        const found_in_mk_questions = state.mk_questions.filter(function (
          question
        ) {
          return question.id == question_id;
        });
        if (found_in_mk_questions.length > 0) {
          return found_in_mk_questions[0];
        }

        const found_in_post_questions = state.post_questions.filter(function (
          question
        ) {
          return question.id == question_id;
        });
        if (found_in_post_questions.length > 0) {
          return found_in_post_questions[0];
        }
      };
    },
  },

  actions: {
    async fetch_mk_questions() {
      if (this.fetch_mk_questions_promise) {
        return this.fetch_mk_questions_promise;
      }
      if (this.mk_questions_loaded) {
        return;
      }
      const loadingStore = useLoadingStore();
      loadingStore.startLoading();
      this.error = null;
      this.fetch_mk_questions_promise = authApi
        .get('/marketing_kit_questions/')
        .then((res) => {
          this.mk_questions = res.data;
          this.mk_questions_loaded = true;
        })
        .catch((error) => {
          this.error = error;
        })
        .finally(() => {
          loadingStore.stopLoading();
          this.fetch_mk_questions_promise = null;
        });
      return this.fetch_mk_questions_promise;
    },

    async fetch_post_questions(post_id: number, force = false) {
      if (this.fetch_post_questions_promise) {
        return this.fetch_post_questions_promise;
      }
      if (this.post_id == post_id && this.post_questions.length > 0 && !force) {
        return;
      }
      if (this.post_id != post_id) {
        this.post_id = post_id;
        this.post_questions = [];
      }
      const loadingStore = useLoadingStore();
      loadingStore.startLoading();
      this.error = null;
      this.fetch_post_questions_promise = authApi
        .get('/post_questions/' + post_id)
        .then((res) => {
          this.post_questions = res.data;
        })
        .catch((error) => {
          this.error = error;
        })
        .finally(() => {
          this.fetch_post_questions_promise = null;
          loadingStore.stopLoading();
        });
      return this.fetch_post_questions_promise;
    },

    async save_new_response(
      question_id: number | undefined,
      new_response: string
    ) {
      const loadingStore = useLoadingStore();
      try {
        loadingStore.startLoading();
        await authApi.post(
          '/user_response/' + this.post_id + '/' + question_id,
          {
            response: new_response,
          }
        );
        let q_index = this.mk_questions.findIndex(
          (question) => question.id == question_id
        );
        if (q_index >= 0) {
          this.mk_questions[q_index].response = new_response;
          this.mk_questions[q_index].response_changed = new Date();
          const mkStore = useMarketingKitStore();
          if (!mkStore.mk_completed) {
            /**
             * This is to update is_mk_complete property, which affects the UI.
             */
            mkStore.fetch_marketing_kits(true);
          }
        }
        q_index = this.post_questions.findIndex(
          (question_1) => question_1.id == question_id
        );
        if (q_index >= 0) {
          this.post_questions[q_index].response = new_response;
          this.post_questions[q_index].response_changed = new Date();
          if (this.post_questions[q_index].is_used_in_condition) {
            /**
             * If this question, response to which we've just updated, is used in
             * a condition that determines whether another question should be
             * displayed or hidden, we need to re-fetch all the post questions,
             * because some of them may now get hidden (or new ones shown) based
             * on the updated response.
             */
            this.fetch_post_questions(this.post_id, true);
          }
        }
      } catch (error) {
        this.error = error as AppError;
        throw error;
      } finally {
        loadingStore.stopLoading();
      }
    },
  },
});
