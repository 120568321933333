import { route } from 'quasar/wrappers';
import {
  createMemoryHistory,
  createRouter,
  createWebHashHistory,
  createWebHistory,
} from 'vue-router';

import routes from './routes';
import { IAppPage } from './navigationLinks';

/*
 * If not building with SSR mode, you can
 * directly export the Router instantiation;
 *
 * The function below can be async too; either use
 * async/await or return a Promise which resolves
 * with the Router instance.
 */

const createHistory = process.env.SERVER
  ? createMemoryHistory
  : process.env.VUE_ROUTER_MODE === 'history'
  ? createWebHistory
  : createWebHashHistory;

const Router = createRouter({
  scrollBehavior: () => ({ left: 0, top: 0 }),
  routes,

  // Leave this as is and make changes in quasar.conf.js instead!
  // quasar.conf.js -> build -> vueRouterMode
  // quasar.conf.js -> build -> publicPath
  history: createHistory(process.env.VUE_ROUTER_BASE),
});

export { Router };

export default route(function (/* { store, ssrContext } */) {
  Router.beforeEach((to, from, next) => {
    if (
      (to.path == '/' &&
        (from.path == '/' || from.name == IAppPage.AuthCallback)) ||
      to.name == IAppPage.AuthCallback
    ) {
      // Load Home and authentication flow without delay
      next();
    } else {
      /**
       * Delay all other page transitions by 200ms to allow for the ripple effect on buttons to complete.
       */
      setTimeout(() => {
        next();
      }, 200);
    }
  });

  /**
   * An attempt to work around the 'Failed to fetch dynamically imported module'
   * error that sometimes occurs during re-deployment of the app.
   * https://stackoverflow.com/questions/69300341/typeerror-failed-to-fetch-dynamically-imported-module-on-vue-vite-vanilla-set
   */
  Router.onError((error, to) => {
    if (error.message.includes('Failed to fetch dynamically imported module')) {
      window.location.href = to.fullPath;
    }
  });

  return Router;
});
