import mitt from 'mitt';

export interface NotificationEvent {
  type: 'positive' | 'negative' | 'warning' | 'info';
  message: string;
}

type AppEvents = {
  notification: NotificationEvent;
  // Add more events as needed
};

const eventBus = mitt<AppEvents>();

export default eventBus;
