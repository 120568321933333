import { defineStore } from 'pinia';
import { authApi } from 'src/boot/axios';
import { LocalStorage } from 'quasar';
import { ChatMessage, ContentPlan } from 'components/models';
import { useChatMessagesStore } from './chat-messages';
import { useUserStore } from './user';
import { useLoadingStore } from './loading';
import { AppError } from 'src/includes/app-error';

type ThisStoreState = {
  content_plans: ContentPlan[];
  active_content_plan_id: number | null;
  error: AppError | null;
  loaded: boolean;
  accepted: boolean;
  fetch_content_plan_promise: Promise<void> | null;
};

const CONTENT_PLAN_ID_STORAGE_KEY = 'cogniscript_active_content_plan_id';

export const useContentPlanStore = defineStore('contentPlan', {
  state: () =>
    ({
      content_plans: [],
      active_content_plan_id: 0,
      error: null,
      loaded: false,
      accepted: false,
      fetch_content_plan_promise: null,
    } as ThisStoreState),

  getters: {
    have_accepted_generated_content_plan(state) {
      if (state.accepted) {
        return true;
      }
      const user = useUserStore();
      if (!user.has_adaptive_content_plan) {
        return false;
      }
      state.accepted = state.content_plans.length > 0;
      return state.accepted;
    },
    get_active_content_plan_id(state) {
      if (!state.active_content_plan_id) {
        state.active_content_plan_id = LocalStorage.getItem(
          CONTENT_PLAN_ID_STORAGE_KEY
        );
      }
      /**
       * If no plan ID has been found in LocalStorage, then choose the last
       * accepted content plan.
       */
      if (!state.active_content_plan_id && state.content_plans.length > 0) {
        state.active_content_plan_id =
          state.content_plans[state.content_plans.length - 1].id;
      }
      return state.active_content_plan_id;
    },
  },
  actions: {
    async fetch_content_plan() {
      if (this.fetch_content_plan_promise) {
        return this.fetch_content_plan_promise;
      }
      if (this.loaded) {
        return;
      }
      const loadingStore = useLoadingStore();
      loadingStore.startLoading();
      this.error = null;
      this.fetch_content_plan_promise = authApi
        .get('/content_plan/')
        .then((res) => {
          this.content_plans = res.data;
          this.loaded = true;
        })
        .catch((error) => {
          this.error = error;
        })
        .finally(() => {
          loadingStore.stopLoading();
          this.fetch_content_plan_promise = null;
        });
      return this.fetch_content_plan_promise;
    },
    accept_generated_content_plan(message_id: number) {
      const loadingStore = useLoadingStore();
      loadingStore.startLoading();
      authApi
        .post('/content_plan/' + message_id)
        .then((res) => {
          this.loaded = false;
          this.fetch_content_plan();
          this.accepted = true;
          const processed_message: ChatMessage = res.data;
          this.save_active_content_plan_id(processed_message.content_plan_id);
        })
        .catch((error) => {
          this.error = error;
        })
        .finally(() => {
          const chat_messages = useChatMessagesStore();
          chat_messages.fetch_chat_messages();
          loadingStore.stopLoading();
        });
    },
    save_active_content_plan_id(connent_plan_id: number) {
      this.active_content_plan_id = connent_plan_id;
      LocalStorage.set(CONTENT_PLAN_ID_STORAGE_KEY, connent_plan_id);
    },
  },
});
