import { boot } from 'quasar/wrappers';
import axios, {
  AxiosError,
  AxiosInstance,
  InternalAxiosRequestConfig,
} from 'axios';
import { useUserStore } from 'stores/user';
import { buildAppError } from 'src/includes/app-error';
import type { AppError } from 'src/includes/app-error';
import eventBus from 'src/includes/event-bus';
import * as Sentry from '@sentry/vue';
import { Router } from 'vue-router';

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $axios: AxiosInstance;
  }
}

// Be careful when using SSR for cross-request state pollution
// due to creating a Singleton instance here;
// If any client changes this (global) instance, it might be a
// good idea to move this instance creation inside of the
// "export default () => {}" function below (which runs individually
// for each client)

/**
 * This instance is for accessing our backend API endpoints that do not require
 * authentication.
 */
const publicApi = axios.create({
  baseURL: (process.env.BACKEND_SRV as string) + process.env.API_URL_PREFIX,
  withCredentials: true,
});

/**
 * This one is for the API endpoints that require authentication.
 */
const authApi = axios.create({
  baseURL: (process.env.BACKEND_SRV as string) + process.env.API_URL_PREFIX,
  withCredentials: true,
});

authApi.interceptors.request.use(addAuthorizationToken);

async function addAuthorizationToken(req: InternalAxiosRequestConfig) {
  const userStore = useUserStore();
  const token = userStore.token;
  if (token) {
    req.headers.Authorization = `Bearer ${token}`;
  } else {
    // Reject the request to prevent it from being sent
    // Create an AxiosError with a 401 status
    const error = new AxiosError(
      'Unauthorized: No authentication token',
      'ERR_NO_TOKEN',
      req
    );
    error.response = {
      status: 401,
      statusText: 'Unauthorized',
      headers: {},
      config: req,
      data: { detail: 'No authentication token' },
    };
    return Promise.reject(error);
  }
  return req;
}

function handleResponseError(error: AxiosError, router: Router) {
  // Transform the raw error into an AppError (for consistent handling in stores)
  const appError: AppError = buildAppError(error);

  if (appError.status === 401) {
    /**
     *  Not authenticated - we used to redirect to Login in this case, but
     *  now we no longer do that. Instead, we handle the non-authenticated
     *  stated at the page level, providing some functionality even to
     *  non-logged-in users.
     */
  } else if (appError.status === 403) {
    /**
     * Permission denied, which means expired subscription. We used to
     * redirect to Profile in this case, but now we don't want to do that.
     * Instead, we will show an error message on the component page and
     * offer a manual transition to Profile from there.
     *
     * router.push({ name: IAppPage.Profile });
     */
  } else {
    /**
     * Do not report network errors during the "Login Now" flow. For some reason,
     * they occur intermittently there and we don't want to scare the users away.
     */
    const skipNotification =
      appError.isNetworkError &&
      router.currentRoute.value.path.startsWith('/login/now/');

    if (!skipNotification) {
      // Emit Notification Event
      eventBus.emit('notification', {
        type: appError.isNetworkError ? 'warning' : 'negative',
        message: appError.message,
      });

      let validationMessage: string | null = null;

      if (appError.status === 422) {
        const responseData = appError.data as {
          detail?: { loc?: string[]; msg?: string }[];
        };

        const validationErrors = Array.isArray(responseData?.detail)
          ? responseData.detail
          : [];

        // Convert the array into a readable string
        const messages = validationErrors.map((err) => {
          const location = Array.isArray(err.loc)
            ? err.loc.join('.')
            : 'unknown';
          const message = err.msg ?? 'Unknown error';
          return `${location}: ${message}`;
        });

        // Assign validation message only if errors exist
        validationMessage = messages.length > 0 ? messages.join(' | ') : null;
      }

      // Report Error to Sentry
      if (appError.message) {
        Sentry.captureMessage(appError.message, {
          level: 'error',
          extra: {
            appError: appError,
            axiosError: error,
            validationMessage: validationMessage,
          },
        });
      } else {
        Sentry.captureMessage('AxiosError without message', {
          level: 'error',
          extra: {
            appError: appError,
            axiosError: error,
          },
        });
      }
    }
  }

  // Rethrow the AppError so Pinia stores or components can handle it.
  //    In a store action, you'll do: `catch(err) => { // err is now AppError }`
  return Promise.reject(appError);
}

export default boot(({ app, router }) => {
  authApi.interceptors.response.use(
    // Successful response, just return it
    (response) => response,
    (error) => handleResponseError(error, router)
  );

  publicApi.interceptors.response.use(
    (response) => response,
    (error) => handleResponseError(error, router)
  );

  // Make Axios available globally
  app.config.globalProperties.$axios = axios;
  // ^ ^ ^ this will allow you to use this.$axios (for Vue Options API form)
  //       so you won't necessarily have to import axios in each vue file
});

export { authApi, publicApi };
