import { defineStore } from 'pinia';
import { authApi } from 'src/boot/axios';
import { Topic } from 'components/models';
import { useUserStore } from './user';
import { AppError } from 'src/includes/app-error';
import { useLoadingStore } from './loading';
import { IAppPage } from 'src/router/navigationLinks';
import * as Sentry from '@sentry/vue';

export const emptyTopic: Topic = {
  id: 0,
  sorting_number: 0,
  topic: '',
  description: '',
  instructions: '',
  content_type: 'user_text',
  content_plan_id: 0,
  post_category_id: 0,
  can_change_category: false,
  format_id: 0,
  style_id: 0,
  language_id: 0,
  lora_project_id: 0,
};

type ThisStoreState = {
  topics: Topic[];
  content_plan_id: number;
  error: AppError | null;
  fetchTopicsPromise: Promise<void> | null;
};

export const useTopicsStore = defineStore('topics', {
  state: () =>
    ({
      topics: [],
      content_plan_id: 0,
      categories: [],
      error: null,
      fetchTopicsPromise: null,
    } as ThisStoreState),

  getters: {
    get_topics: (state) => (content_plan_id: number) => {
      return state.content_plan_id === content_plan_id ? state.topics : [];
    },
    get_topic: (state) => (topic_id: number) => {
      return state.topics.find((topic) => topic.id === topic_id) || emptyTopic;
    },
  },
  actions: {
    async fetch_topics(content_plan_id?: number) {
      if (this.fetchTopicsPromise) {
        return this.fetchTopicsPromise;
      }

      if (content_plan_id) {
        if (this.content_plan_id == content_plan_id && this.topics.length > 0) {
          // Topics already fetched
          return;
        }
        this.content_plan_id = content_plan_id;
      }

      if (this.content_plan_id < 0 || !this.content_plan_id) {
        return;
      }

      const loadingStore = useLoadingStore();
      loadingStore.startLoading();

      this.error = null;
      this.fetchTopicsPromise = authApi
        .get('/content_plan_topics/' + this.content_plan_id)
        .then((res) => {
          if (!Array.isArray(res.data)) {
            Sentry.captureException(
              new Error(
                'Expected res.data to be an array, but got ' + typeof res.data
              ),
              {
                extra: {
                  res_data: res.data,
                },
              }
            );
            this.topics = [];
          } else {
            this.topics = res.data;
          }
        })
        .catch((error) => {
          this.error = error;
        })
        .finally(() => {
          loadingStore.stopLoading();
          this.fetchTopicsPromise = null;
        });
      return this.fetchTopicsPromise;
    },
    save_topic_edits(updated_topic: Topic) {
      this.update_topic_in_store(updated_topic);

      authApi
        .patch('/post_details/' + updated_topic.id, updated_topic)
        .catch((error) => {
          this.error = error;
        });
    },
    update_topic_in_store(updated_topic: Topic) {
      const t_index = this.topics.findIndex(
        (topic) => topic.id == updated_topic.id
      );
      if (t_index >= 0) {
        this.topics[t_index] = updated_topic;
      }
    },
    create_new_topic(new_topic: Topic) {
      const loadingStore = useLoadingStore();
      loadingStore.startLoading();
      authApi
        .post('/post_details/', new_topic)
        .then((res) => {
          this.process_addition(IAppPage.SingleStandaloneText, res.data);
          this.reload_user_if_personal_texts_id_not_set();
        })
        .catch((error) => {
          this.error = error;
        })
        .finally(() => {
          loadingStore.stopLoading();
        });
    },
    delete_topic_on_frontend(post_id: number) {
      const topic_index = this.topics.findIndex((topic) => topic.id == post_id);
      if (topic_index >= 0) {
        this.topics.splice(topic_index, 1);
      }
    },
    process_addition(page: IAppPage, new_record: Topic) {
      /**
       * Add new topic to the topics array so that it is immediately available.
       */
      this.topics.push(new_record);
      /**
       * Attempt to re-fetch the whole list of topics. This is just in case
       * there is some mixup with the content_plan_ids. If the content_plan_id
       * hasn't changed, there will be no additional network request.
       */
      this.fetch_topics(new_record.content_plan_id);
      /**
       * Redirect to the new post's page.
       */
      if (new_record?.content_plan_id && new_record?.id) {
        this.router.push({
          name: page,
          params: {
            content_plan_id: new_record.content_plan_id,
            post_id: new_record.id,
          },
        });
      }
    },
    reload_user_if_personal_texts_id_not_set() {
      /**
       * If this was the user's first personal text, we've probably just
       * updated (set) the personal_texts_id for him, so we want to re-fetch
       * the user object now.
       */
      const userStore = useUserStore();
      if (!userStore.user.active_profile.personal_texts_id) {
        userStore.fetchUser(true);
      }
    },
    reload_user_if_image_requests_id_not_set() {
      /**
       * If this was the user's first image request, we've probably just
       * updated (set) the image_requests_id for him, so we want to re-fetch
       * the user object now.
       */
      const userStore = useUserStore();
      if (!userStore.user.active_profile.image_requests_id) {
        userStore.fetchUser(true);
      }
    },
    async set_post_category(topic: Topic, category_id: number) {
      const loadingStore = useLoadingStore();
      loadingStore.startLoading();
      await authApi
        .post('/post_categories/' + topic.id + '/' + category_id)
        .then(() => {
          topic.post_category_id = category_id;
        })
        .catch((error) => {
          this.error = error;
        })
        .finally(() => {
          loadingStore.stopLoading();
        });
    },
    save_user_personal_text(new_text: string) {
      const loadingStore = useLoadingStore();
      loadingStore.startLoading();
      authApi
        .post('/texts/', new_text)
        .then((res) => {
          this.process_addition(IAppPage.SingleStandaloneText, res.data);
          this.reload_user_if_personal_texts_id_not_set();
        })
        .catch((error) => {
          this.error = error;
        })
        .finally(() => {
          loadingStore.stopLoading();
        });
    },
    save_user_image_request(new_request: string) {
      const loadingStore = useLoadingStore();
      loadingStore.startLoading();
      authApi
        .put('/images/', new_request)
        .then((res) => {
          this.process_addition(IAppPage.CreateStandaloneImage, res.data);
          this.reload_user_if_image_requests_id_not_set();
        })
        .catch((error) => {
          this.error = error;
        })
        .finally(() => {
          loadingStore.stopLoading();
        });
    },
  },
});
