import { defineStore } from 'pinia';
import { authApi } from 'src/boot/axios';
import { ContentPlan } from 'components/models';
import { useUserStore } from './user';
import { useQuestionsStore } from './questions';
import { useContentPlanStore } from './content-plan';
import { useLoadingStore } from './loading';
import { AppError } from 'src/includes/app-error';

type ThisStoreState = {
  content_plans: ContentPlan[];
  error: AppError | null;
  loaded: boolean;
};

function fetch_content_plans(state: ThisStoreState) {
  const loadingStore = useLoadingStore();
  loadingStore.startLoading();
  state.error = null;
  authApi
    .get('/content_plans/')
    .then((res) => {
      state.content_plans = res.data;
      state.loaded = true;
    })
    .catch((error) => {
      state.error = error;
    })
    .finally(() => loadingStore.stopLoading());
}

export const useContentPlansStore = defineStore('contentPlans', {
  state: () =>
    ({
      content_plans: [],
      error: null,
      loaded: false,
    } as ThisStoreState),

  getters: {
    get_content_plans(state) {
      if (!state.loaded) {
        fetch_content_plans(state);
      }
      return state.content_plans;
    },
  },

  actions: {
    async choose_module(content_plan_id: number) {
      try {
        const res = await authApi.post('/content_plans/' + content_plan_id);
        this.content_plans = res.data;

        const user = useUserStore();
        await user.fetchUser();

        const mk_questions = useQuestionsStore();
        mk_questions.mk_questions_loaded = false;

        const content_plan_store = useContentPlanStore();
        content_plan_store.save_active_content_plan_id(0);
      } catch (error) {
        this.error = error as AppError;
      }
    },
  },
});
